@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
@import 'variables.scss';
@import 'fonts.scss';
@import 'select.scss';
@import 'sidebar.scss';
@import 'my-course.scss';
@import 'video.scss';
@import 'activity.scss';
@import './components/_calculator.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: techNology;
  src: url('../assets/fonts/Technology/Technology.ttf');
}

#globalLoader {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  @apply backdrop-blur-sm;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

*:not(math, math *),
*:not(math, math *)::after,
*:not(math, math *)::before,
*:not(math, math *):not(.editor-wrap),
*:not(math, math *)::after:not(.editor-wrap),
*:not(math, math *)::before:not(.editor-wrap) {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

* .sheet,
* .iconfont::before,
.sheet *,
* .sheet,
.sheet *::before {
  font-family: unset;
}

* .fa,
* .fa::before {
  font-family: 'Font Awesome 5 Free';
}

html {
  font-size: 16px;
}

body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  &::-webkit-scrollbar {
    @apply h-4 w-1.5 border bg-bw-3;
  }

  &::-webkit-scrollbar-track {
    @apply border;
  }

  &::-webkit-scrollbar-thumb {
    @apply border bg-primary;
  }
}

/* Mac */
body *::-webkit-scrollbar {
  width: 1.0625rem;
}

body *::-webkit-scrollbar-track {
  background-color: transparent;
}

body *::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 1.25rem;
  border: 0.375rem solid transparent;
  background-clip: content-box;
}

body *::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.menu-sub-dropdown {
  @apply hidden;
}

.active + .menu-sub-dropdown {
  @apply block;
}

.table-responsive tr:last-child td {
  @apply border-b-0;
}

.solution .topic p {
  @apply mb-6;
}

.sapp-answer-wrapper {
  @apply flex flex-wrap gap-y-6 pt-2;

  .answer {
    @apply flex items-start gap-x-3 break-words;

    .desciption {
      @apply flex-1;
    }
  }
}

.sapp-questions {
  @apply mb-4;

  p {
    @apply mb-2 break-words;
  }
}

.editor-wrap {
  @apply font-sans;
  hr {
    @apply hidden;
  }

  a {
    @apply font-semibold text-primary;
  }

  table {
    border-color: gray;
    border-style: inset;
  }

  p {
    @apply mb-2 break-words;
  }

  thead,
  tbody,
  tfoot,
  td,
  th {
    // border-width: 1px;
    border-style: inset;
    border-color: #a1a1a1;
    /* Add this line to set the border color to gray-2 */
  }

  tr {
    border-style: initial;
    border-width: initial;
  }

  ol,
  ul,
  menu {
    padding-inline-start: 2.5rem !important;
    list-style: auto;
  }

  ul {
    list-style-type: disc;
  }

  hr {
    @apply border-default;
  }

  .sapp_overlay_video {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 1;

    &::before {
      color: white;
      content: '\25B6';
      opacity: 0.5;
      cursor: pointer;
      text-shadow: 0 0.1875rem black;

      font-size: 3.125rem;
    }
  }

  img.Wirisformula {
    width: auto !important;
    display: unset;
    max-width: max-content;
  }

  .video-controls ul {
    padding-inline-start: 0 !important;
    list-style: none;
  }
}

.sapp-questions .sapp-input-preview {
  // @apply w-24 h-7 rounded-md mb-1;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 0.125rem;
  padding: 0.5rem;
  width: 6.875rem;
  height: 2.1875rem;
  margin-bottom: 0.25rem;

  @apply bg-gray-4 text-center text-base font-bold;
}

.sapp-select--selectword-preview,
.sapp-select--selectword-preview:focus {
  @apply min-w-78px rounded;
  --tw-ring-color: unset;
  --tw-ring-offset-shadow: unset;
  --tw-ring-shadow: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #f9f9f9;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="non"><path d="M10.9827 0.571289H9.97827C9.90996 0.571289 9.84568 0.604771 9.8055 0.659682L6.00059 5.90433L2.19568 0.659682C2.1555 0.604771 2.09121 0.571289 2.02291 0.571289H1.01845C0.931392 0.571289 0.880499 0.670396 0.931392 0.741379L5.65371 7.25165C5.82514 7.48736 6.17603 7.48736 6.34612 7.25165L11.0684 0.741379C11.1207 0.670396 11.0698 0.571289 10.9827 0.571289Z" fill="%23DCDDDD" /></svg>');
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 0.8125rem;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 0.125rem;
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  width: 6.875rem;
  height: 2.1875rem;
  max-width: 9.375rem;
  margin-bottom: 0.5rem;

  &:required:invalid {
    color: #dcdddd;
  }

  option {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  option[value=''][disabled] {
    display: none;
  }

  option[disabled] {
    color: #dcdddd;
  }

  option {
    color: #404041;
  }

  &.sapp-select-confirmed {
    background-image: none;
    text-align: center;
    padding: 0;
    @apply bg-gray-4;
  }
}

.sapp-notched-container {
  @apply relative z-10 flex h-full flex-1 items-center justify-start break-words border border-solid border-gray-6 bg-gray-3 p-5 ps-8;

  &.dragging {
    @apply z-20;
  }
}

.sapp-notched-container:before {
  content: '';
  left: 0;
  /* top: 50%; */
  width: 1.875rem;
  height: 1.875rem;
  transform: translate(-50%, 0) rotate(-135deg);
  // clip-path: polygon(0px 0px, 0% 100%, 100% 100%);
  @apply absolute z-0 border border-r-0 border-t-0 border-solid border-gray-6 bg-white;
}

.sapp-arrowed-container {
  @apply relative flex h-auto w-[40%] items-center justify-start break-words border border-solid border-gray-6 bg-gray-3 p-5;
}

.sapp-arrowed-container:before {
  border: 0.0625rem dashed rgb(224, 221, 227);
  content: '';
  right: -0.0625rem;
  /* top: 50%; */
  width: 1.875rem;
  height: 1.875rem;
  transform: translate(50%, 0) rotate(-135deg);
  // clip-path: polygon(0px 0px, 0% 100%, 100% 100%);
  @apply absolute z-0 border border-r-0 border-t-0 border-solid border-gray-6 bg-gray-3;
}

.sapp-arrowed-container-corrects {
  @apply relative flex h-auto w-[48%] items-center justify-start break-words border border-solid border-success bg-bw-6 p-5;
}

.sapp-arrowed-container-corrects:before {
  border: 0.0625rem dashed rgb(224, 221, 227);
  content: '';
  right: -0.0625rem;
  /* top: 50%; */
  width: 1.875rem;
  height: 1.875rem;
  transform: translate(50%, 0) rotate(-135deg);
  // clip-path: polygon(0px 0px, 0% 100%, 100% 100%);
  @apply absolute z-10 border border-r-0 border-t-0 border-solid border-success bg-bw-6;
}

.sapp-notched-container-corrects {
  @apply relative flex h-full flex-1 items-center justify-start break-words border border-l-0 border-solid border-success bg-bw-6 p-5 ps-8;
}

.sapp-notched-container-corrects:before {
  content: '';
  left: -0.075rem;
  /* top: 50%; */
  width: 1.875rem;
  height: 1.875rem;
  transform: translate(-50%, 0) rotate(-135deg);
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  @apply absolute z-0 border-solid border-success bg-bw-6;
}

.sapp-arrowed-container-incorrects {
  @apply relative flex h-auto w-[48%] items-center justify-start break-words border border-solid border-error bg-bw-6 p-5;
}

.sapp-arrowed-container-incorrects:before {
  border: 0.0625rem dashed rgb(224, 221, 227);
  content: '';
  right: -0.0625rem;
  /* top: 50%; */
  width: 1.875rem;
  height: 1.875rem;
  transform: translate(50%, 0) rotate(-135deg);
  // clip-path: polygon(0px 0px, 0% 100%, 100% 100%);
  @apply absolute z-10 border border-r-0 border-t-0 border-solid border-error bg-bw-6;
}

.sapp-notched-container-incorrects {
  @apply relative flex h-full flex-1 items-center justify-start break-words border border-l-0 border-solid border-error bg-bw-6 p-5 ps-8;
}

.sapp-notched-container-incorrects:before {
  content: '';
  left: -0.075rem;
  /* top: 50%; */
  width: 1.875rem;
  height: 1.875rem;
  border: 0;
  transform: translate(-50%, 0) rotate(-135deg);
  clip-path: polygon(0px 0px, 0% 100%, 100% 100%);
  @apply absolute z-0 border-solid border-error bg-bw-6;
}

.answer-area {
  @apply mt-4 flex flex-wrap gap-5;
}

.answer-box {
  border: 0.0625rem solid rgb(204, 204, 204);
  background: rgb(243, 243, 243);
  @apply z-10 block p-2;
}

.sapp-input-dragNDrop:not(:has(.answer-box)) {
  @apply relative min-h-[3.125rem] min-w-[6.25rem] border-0 border-b border-gray-500;
}

.sapp-input-dragNDrop:not(:has(.answer-box))::after {
  content: ' (' attr(indexBox) ')';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 20%);
  background: white;
  @apply text-gray-1;
}

.sapp-input-dragNDrop {
  @apply inline-block;
}

.sapp-input-dragNDrop-answer {
  @apply m-1 inline-block min-w-[6.25rem] border border-gray-500 bg-gray-4 p-1;

  &.corrects {
    @apply border-success text-state-success;
  }

  &.wrongs {
    @apply border-error text-state-error;
  }
}

.sapp-input-dragNDrop-empty {
  @apply inline-block min-w-[6.25rem] border-0 text-center;
}

.sapp-questions-essay {
  line-height: 2.0625rem;
  @apply mb-4 font-sans text-base font-semibold text-bw-1;
}

.sapp-seprate-line-preview {
  @apply -ms-6 mb-4 h-3 w-[calc(100%+3rem)] bg-gray-3;
}

.sapp-upload-file-preview {
  @apply mb-4 flex items-center gap-3 px-0;
}

.sapp-upload-button-preview {
  @apply flex w-max cursor-pointer items-center justify-evenly gap-2 border px-2 py-0;
}

.tox-tinymce {
  border-radius: 0 !important;
}

.resizable {
  @apply left-[6.25rem] top-[6.25rem] cursor-move bg-white;
}

.resizable .resizers {
  display: none;
  @apply z-[500] box-border h-full w-full;
}

.resizable.enable .resizers {
  display: block;
  // border: 2px solid #7e8299;
  @apply border-2 border-gray-900;
}

.resizable .resizers .resizer {
  box-shadow:
    0px 0px 0px 0.0625rem rgba(43, 59, 74, 0.3),
    0px 0px 0.25rem 0.0625rem rgba(57, 76, 96, 0.15);
  @apply absolute z-[500] h-[0.75rem] w-[0.75rem] rounded-full bg-white;
}

.resizable .resizers .resizer.top-left {
  @apply -left-[0.3125rem] -top-[0.3125rem] cursor-nwse-resize;
}

.resizable .resizers .resizer.top-right {
  @apply -right-[0.3125rem] -top-[0.3125rem] cursor-nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
  @apply -bottom-[0.3125rem] -left-[0.3125rem] cursor-nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
  @apply -bottom-[0.3125rem] -right-[0.3125rem] cursor-nwse-resize;
}

.resizable .resizers .resizer.right {
  @apply -right-[0.15625rem] top-1/2 h-[1.125rem] w-[0.375rem] -translate-y-1/2 cursor-col-resize rounded-[0.1875rem];
}

.resizable .resizers .resizer.left {
  @apply -left-[0.15625rem] top-1/2 h-[1.125rem] w-[0.375rem] -translate-y-1/2 cursor-col-resize rounded-[0.1875rem];
}

.sapp-opacity-bg-border {
  background-color: transparent;
  border: none;
  resize: none;
}

.sapp-text-area {
  resize: none;
  border: none;
}

.result {
  height: 3.75rem;
  width: 100%;
  @apply bg-gray-2;
}

.result p {
  font-size: 2.5rem;
  margin: 0.3125rem;
}

.calculator-body {
  @apply flex h-[calc(100%-2.5rem)] w-full flex-col;
}

.button {
  display: block;
  @apply flex-1 bg-gray-2;
}

.stream-player__progress-bar {
  /* Set the position to relative */
  position: relative;
}

/* Add a point before the progress bar */
.stream-player__progress-bar::before {
  /* Set the position to absolute */
  position: absolute;
  /* Set the content to a circle icon */
  content: '\25cf';
  /* Set the font size to 12px */
  font-size: 0.75rem;
  /* Set the color to white */
  color: white;
  /* Set the left position to 10% of the progress bar width */
  left: 10%;
  /* Set the top position to 50% of the progress bar height */
  top: 50%;
  /* Adjust the position with transform */
  transform: translate(-50%, -50%);
}

/* Add another point after the progress bar */
.stream-player__progress-bar::after {
  /* Set the position to absolute */
  position: absolute;
  /* Set the content to a star icon */
  content: '\2605';
  /* Set the font size to 12px */
  font-size: 0.75rem;
  /* Set the color to yellow */
  color: yellow;
  /* Set the right position to 20% of the progress bar width */
  right: 20%;
  /* Set the top position to 50% of the progress bar height */
  top: 50%;
  /* Adjust the position with transform */
  transform: translate(50%, -50%);
}

.required {
  &:after {
    content: '*';
    position: relative;
    font-size: inherit;
    color: #d35563;
    padding-left: 0.25rem;
    font-weight: 700;
  }
}

.sapp-hover-device-item:hover {
  .sapp-logo {
    @apply border-primary bg-secondary;
  }

  .logo {
    fill: #ffb800;
  }
}

.sapp-disable-button {
  position: relative;

  ::after {
    content: '';
    @apply bg-gray-3;
    // background: white;
    opacity: 0.6;
    width: calc(100% - 0.125rem);
    height: 100%;
    position: absolute;
    left: 0.0625rem;
  }
}

.sapp-separateLine :not(:last-child) {
  @apply border-b border-gray-4;
}

.active {
  .word_icon path {
    fill: #295496;
  }

  .excel_icon path {
    fill: #1d5d36;
  }
}

.word_icon path {
  fill: #dcdddd;
}

textarea.is-invalid:focus {
  --tw-ring-color: #d35563;
  --tw-ring-offset-color: #d35563;
}

@layer utilities {
  .grid-fit-content {
    grid-template-columns: min-content 1fr;
  }
}

.min-w-\[63px\] {
  min-width: 3.9375rem;
}

.max-w-40 {
  max-width: 10rem;
}

.hover-transition-font-weight {
  transition: all color 0.2s ease-in-out;
  &:hover {
    text-shadow: 0px 0px 0.05em #ffb800;
  }
}

.ant-tooltip-inner {
  color: #404041 !important;
  max-width: 37.5rem;
  width: max-content;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0.75rem 0.5rem !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #fffaf0 !important;
}

.ant-dropdown .ant-dropdown-menu {
  padding: 0.5rem !important;
  border-radius: 0 !important;
}

.ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content li span {
  color: #ffb800;
}

.learningOutcome-description {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block !important;
    font-weight: bold !important;
  }
  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em !important;
  }
  h5 {
    font-size: 0.83em !important;
  }
  h6 {
    font-size: 0.67em !important;
  }
  pre {
    display: block !important;
    font-family: monospace !important;
    white-space: pre !important;
  }
  p {
    display: block;
    font-size: 0.875rem;
  }
}

.ant-modal .ant-modal-content {
  border-radius: 0 !important;
}

.sapp-modal .ant-modal-content {
  padding: 4.375rem 4.75rem !important;
}

.sapp-modal--math .ant-modal-content {
  width: fit-content;
  padding: 1.25rem !important;
  margin: auto;
}

.sapp-modal--math.ant-modal {
  width: fit-content !important;
}

.sapp-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  @apply text-4xl font-semibold text-bw-1;
}

.ant-modal-root .ant-modal-wrap {
  @apply bg-bw-10;
}

.lf-player-container {
  height: inherit;
  @apply flex items-center justify-center;
}

// Animate the size, inside
.secondary:hover,
// .secondary:focus,
.primary:hover {
  box-shadow: inset 0 0 0 2em var(--hover);
}

$colors: (
  secondary: white,
  primary: #ffd81b,
);

$hover: (
  secondary: #ffb800,
  primary: #ffc83a,
);

$focus: (
  primary: #ffb800,
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
// Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{map-get(
        $hover,
        $button
      )}; // Sử dụng màu hover tương ứng với mỗi button
    transition: background-color 0.3s ease; // Hiệu ứng transition cho màu nền
  }

  .#{$button}:hover {
    --color: map-get(
      $hover,
      $button
    ); // Sử dụng màu hover tương ứng với mỗi button khi hover hoặc focus
  }
}

// Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
.secondary {
  color: #404041;
  transition: 0.25s;
  @apply bg-gray-3;

  &:hover {
    @apply text-white;
  }
}

.primary {
  transition: 0.25s;
  @apply bg-primary text-white;

  &:hover {
    @apply text-white;
  }
}

.ant-drawer .ant-drawer-body {
  padding: 0 !important;
}

.sapp-notifcation.text-bw-1 a:hover {
  @apply text-primary;
}

.ant-drawer {
  position: unset;
}

.ant-drawer .ant-drawer-mask,
.ant-drawer .ant-drawer-mask,
.ant-drawer .ant-drawer-content-wrapper {
  position: fixed;
  background-color: #000000cc;
}

.shadow-activity.aos-init.aos-animate {
  transform: none !important;
}

textarea:focus {
  --tw-ring-color: none;
  @apply border-primary;
}

textarea {
  @apply border-[0.0625rem] border-gray-2;
}

.button-icon {
  transition: 0.25s;

  &:hover {
    @apply bg-primary-2;
  }
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal {
  font-family: 'Roboto' !important;
}

p a {
  @apply text-blue-1 underline;
}

.ant-dropdown {
  z-index: 2000 !important;
}

.ant-tooltip .ant-tooltip-inner {
  padding: 0.625rem 0.75rem !important;
}

#floating-button {
  @apply fixed h-[3.75rem] w-[3.75rem] cursor-pointer rounded-full bg-white;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0 0.0625rem 0.25rem,
    rgba(0, 0, 0, 0.2) 0 0.125rem 0.75rem;
}

.sapp-pinned-noti-header {
  padding: 0.75rem 1.5rem 0.75rem 14.5625rem;
  background: #18355d;
}

.sapp-pinned-noti-header {
  padding: 0.75rem 1.5rem 0.75rem 14.5625rem;
  @apply bg-bw-1;
}

@keyframes moveDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.plus {
  animation: plus-out 0.3s;
  transition: all 0.3s;
  @apply left-[-0.125rem] h-[3.75rem] w-[3.75rem];
}

#container-floating {
  @apply fixed bottom-[1.875rem] right-[1.25rem] z-[50] h-[3.75rem] w-[3.75rem];
}

// @keyframes edit-in {
//   from {
//     opacity: 0;
//     transform: rotateZ(-70deg);
//   }
//   to {
//     opacity: 1;
//     transform: rotateZ(0deg);
//   }
// }

// @keyframes edit-out {
//   from {
//     opacity: 1;
//     transform: rotateZ(0deg);
//   }
//   to {
//     opacity: 0;
//     transform: rotateZ(-70deg);
//   }
// }

// @keyframes plus-in {
//   from {
//     opacity: 1;
//     transform: rotateZ(0deg);
//   }
//   to {
//     opacity: 0;
//     transform: rotateZ(180deg);
//   }
// }

// @keyframes plus-out {
//   from {
//     opacity: 0;
//     transform: rotateZ(180deg);
//   }
//   to {
//     opacity: 1;
//     transform: rotateZ(0deg);
//   }
// }

// @keyframes bounce-nds {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//     transform: scale(1);
//   }
// }

// @keyframes bounce-out-nds {
//   from {
//     opacity: 1;
//     transform: scale(1);
//   }
//   to {
//     opacity: 0;
//     transform: scale(0);
//   }
// }

.ant-popover-content {
  width: 22.5rem !important;
}

.ant-popover-inner {
  padding: 1.5rem !important;
  margin-bottom: 1rem !important;
}

.ant-popover {
  font-family: 'Roboto', sans-serif !important;
}

#hubspot-messages-iframe-container.visible-icon iframe,
#hubspot-messages-iframe-container .visible-icon {
  display: none !important;
}

#hubspot-messages-iframe-container.visible-icon,
#hubspot-messages-iframe-container .visible-icon {
  position: inherit !important;
}

#floating-button:hover,
#close_icon:hover,
#back-to-top:hover {
  transition: transform 100ms ease-in-out 0s;
  transform: scale(1.1);
}

.svg-container:hover path {
  @apply fill-primary;
}

.ant-modal {
  font-family: 'Roboto', sans-serif !important;
}

.content-course div strong {
  @apply font-medium text-bw-1;
}

.sapp-custom--modal .ant-modal-content {
  @apply h-[100vh] w-[100vw] max-w-none bg-transparent;
}

.ant-modal.sapp-custom--modal {
  width: -webkit-fill-available !important;
  @apply max-w-none;
}

// .ant-modal-wrap.ant-modal-centered {
//   @apply w-fit
// }

.tooltip-box div span p {
  @apply mb-0 ml-2;
}

.videoContainer {
  @apply relative h-auto w-full;
}

.loadingOverlay {
  transform: translate(-50%, -50%);
  @apply absolute left-[50%] top-[50%] z-10 rounded-[0.3125rem] p-2.5 text-bw-1;
}

.hotline ins {
  @apply cursor-default no-underline;
}

.fanpage ins {
  @apply no-underline hover:underline;
}

.result-scroll {
  &::-webkit-scrollbar {
    @apply h-4 w-1.5 border bg-bw-3;
  }

  &::-webkit-scrollbar-track {
    @apply border;
  }

  &::-webkit-scrollbar-thumb {
    @apply border bg-primary;
  }
}

.dashboard-scroll-x,
.dashboard-scroll-y {
  overflow: hidden;
}

.dashboard-scroll-x:hover {
  overflow-x: auto;
}

.dashboard-scroll-y:hover {
  overflow-y: auto;
}

.sapp-custom-hover.button-icon:hover {
  @apply bg-transparent;
}

br {
  content: '';
  @apply m-2 block text-sm;
}

td.py-5.px-3.sapp-table-quiz {
  border-width: 0;
}

.sapp-border-none {
  border: 0;
}

.card {
  width: 100%;
  border-radius: var(--border-radius);
}

.card .inner {
  color: #fff;
  border-radius: var(--border-radius);
}

.active-section {
  outline-width: 0.0625rem;
  outline-offset: 0;
  outline-color: #ffb800;
  outline-style: solid;
  animation: animateOutline 4s ease infinite;
}

@keyframes animateOutline {
  0% {
    outline-width: 0.0625rem;
    outline-offset: 0;
    outline-color: rgba(0, 130, 206, 0);
  }

  10% {
    outline-color: #ffb800;
  }

  /* The animation finishes at 50% */
  50% {
    outline-width: 0.1875rem;
    outline-offset: 0.25rem;
    outline-color: rgba(0, 130, 206, 0);
  }

  100% {
    outline-width: 0.25rem;
    outline-offset: 0.25rem;
    outline-color: rgba(102, 102, 102, 0);
  }
}

.responsive-truncate-container {
  overflow: auto;
  width: 95%;
  min-width: 6.25rem;
  max-width: 100%;
}

.sapp-modal--comment .ant-modal-content {
  @apply max-h-[640px] min-h-[400px] p-0;
}

.pined-noti p {
  @apply mb-0;
}

td.sapp-border {
  @apply border-0;
}
