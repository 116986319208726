/* ==========================================================================
   #Custom Video Player
   ========================================================================== */

.flex-center {
  @apply flex items-center justify-center;
}

.sapp-video-custom {
  .video-controls {
    transition: all 0.2s ease;
    background: rgba(104, 104, 104, 0.6);
  }

  .video-controls.hide {
    @apply pointer-events-none opacity-0;
  }

  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  progress::-webkit-progress-bar {
    @apply bg-overlay-white;
  }

  progress::-webkit-progress-value {
    @apply bg-gray-8;
  }

  progress::-moz-progress-bar {
    @apply border border-gray-4 bg-overlay-white;
  }

  .seek:hover + .seek-tooltip {
    @apply block;
  }

  .seek-tooltip {
    content: attr(data-title);
  }

  .left-controls button::before {
    @apply -right-1;
  }

  .volume-controls:hover .volume-process {
    @apply block;
  }

  .volume-controls input {
    transition: all 0.4s ease;
  }

  .volume-controls:hover input,
  .volume-controls input:focus {
    @apply w-full opacity-100;
  }

  .btn-video {
    @apply relative cursor-pointer border-0 p-0 text-ssm outline-0;
  }

  .btn-video * {
    @apply pointer-events-none;
  }

  .btn-video.volume-button {
    &:before {
      @apply right-8;
    }

    &[data-title='Unmute']:before {
      top: -2.9375rem;
    }
  }

  .btn-video::before {
    @apply absolute right-0 top-[-3.125rem] z-10 hidden bg-overlay-control px-1.5 py-1 font-semibold text-white;
    content: attr(data-title);
    word-break: keep-all;
    white-space: pre;
  }

  .btn-video:hover::before {
    @apply inline-block;
  }

  .fullscreen-button {
    @apply mr-0;
  }

  .playback-animation {
    @apply pointer-events-none absolute left-1/2 top-1/2 z-20 -ml-10 -mt-10 h-20 w-20 rounded-full bg-overlay-control opacity-0;
    border-radius: 5rem;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    @apply h-1.5 cursor-pointer bg-overlay-white;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    @apply w-full cursor-pointer;
    border-radius: 0.08125rem;
    -webkit-appearance: none;
    transition: all 0.4s ease;
  }

  input[type='range']::-webkit-slider-thumb {
    @apply -ml-px h-4 w-4 cursor-pointer bg-gray-3;
    -webkit-appearance: none;
    border-radius: 1rem;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
  }

  input[type='range'].volume {
    @apply h-1.5 bg-white;
  }

  input[type='range'].volume::-webkit-slider-runnable-track {
    @apply bg-transparent;
  }

  input[type='range'].volume::-webkit-slider-thumb {
    @apply ml-0 h-3.5 w-3.5 bg-white;
  }

  input[type='range']::-moz-range-track {
    @apply h-1.5 h-1.5 w-full cursor-pointer;
    border: 0.0625rem solid transparent;
    background: transparent;
    border-radius: 0.08125rem;
  }

  input[type='range']::-moz-range-thumb {
    @apply h-3.5 w-3.5 cursor-pointer border border-gray-4 bg-gray-8;
    border-radius: 3.125rem;
  }

  input[type='range']:focus::-moz-range-track {
    outline: none;
  }

  input[type='range'].volume::-moz-range-thumb {
    @apply border border-white bg-white;
  }

  .volume-button {
    @apply mr-0;
  }

  .volume-process {
    @apply absolute bottom-[200%] right-[-150%] z-20 hidden w-[7.1875rem] rounded-sm bg-overlay-play px-2 pb-2 pt-1;
    transform: rotate(270deg);
  }

  .icon-svg {
    @apply cursor-pointer;
    fill: #ffffff;
    stroke: #ffffff;
  }

  .settings-control {
    &.active {
      .settings-control-popup {
        @apply block;
      }
    }
  }
}
